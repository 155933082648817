const config = {
  graphqlEndpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  apiUrl: process.env.REACT_APP_API_URL,
  BulletinType: {
    Ward: 'Ward',
    Stake: 'Stake',
  },
  EventType: {
    Ward: 'Ward',
    Stake: 'Stake',
  },
  alignment: {
    center: 'center',
    left: 'left',
    right: 'right',
    centerVertically: 'centerVertically',
    centerHorizontally: 'centerHorizontally',
  },
  blogMenuPlacement: {
    header: 'header',
    footer: 'footer',
  },
  imageSize: {
    small: 'small',
    medium: 'medium',
    large: 'large',
    original: 'original',
  },
  buttonType: {
    button: 'button',
    link: 'link',
  },
  columnType: {
    basic: 'basic',
    columns: 'columns',
  },
}

export default config;
