import { lazy } from 'react';

const routes = {
  home: {
    path: '/',
    component: lazy(() => import('./containers/Page'))
  },
  page: {
    path: (slug: string) => `/${slug}`,
    component: lazy(() => import('./containers/Page'))
  },
  blogs: {
    path: '/blog',
    component: lazy(() => import('./containers/Blogs'))
  },
  blogBySlug: {
    path: (slug: string) => `/blog/${slug}`,
    component: lazy(() => import('./containers/Blog'))
  }
}

export default routes;
