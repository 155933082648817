import { useEffect } from 'react';
import { ApolloProvider, ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { createBrowserHistory } from 'history';
import { Router } from "react-router-dom";

import * as link from './link';
import Layout from './Layout';

import './App.scss';

const client = new ApolloClient({
  link: ApolloLink.from([
    //link.errorLink,
    link.authLink,
    link.httpLink,
  ]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      errorPolicy: 'ignore',
    },
  },
});


export const history = createBrowserHistory();

function App() {
  useEffect(() => {
    document.title = 'Loading...';
  }, [])

  return (
    <ApolloProvider client={client}>
      <div className="main-container">
        <Router history={history}>
          <Layout />
        </Router>
      </div>
    </ApolloProvider>
  );
}

//export default withAuthContext(App);
export default App;
