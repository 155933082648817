import { useState } from 'react';
import { Link } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';

import config from '../../config';

import styles from './style.module.scss';

const PAGE_LIST = gql`
  query ($input: PagePagingInput) {
    Page(input: $input) {
      data {
        id
        title
        isHomePage
        slug
        isInMainMenu
        isInFooter
      }
    }
  }
`

const SETTING = gql`
  query Setting {
    Setting {
      blogMenuPlacement
      header {
        companyLogo {
          url
        }
      }
    }
  }
`;

const Header = () => {
  const [menuToggled, setMenuToggled] = useState(false);
  const { data: settingData } = useQuery(SETTING, {
    fetchPolicy: 'cache-first',
  });

  const { data: pageData } = useQuery(PAGE_LIST, {
    variables: {
      input: {
        paging: {
          orderBy: 'createdAt.asc',
        },
        args: {
          display: true,
        }
      },
    },
  });

  const headerLogo = settingData?.Setting?.[0]?.header?.companyLogo?.url;
  const blogMenuPlacement = settingData?.Setting?.[0]?.blogMenuPlacement;

  const toggleMenu = () => {
    setMenuToggled((prev: boolean) => {
      return !prev;
    })
  }

  return (
    <header>
      <div className={styles['container']}>
        <div className={styles['logo']}>
          <Link to="/">
            {
             headerLogo &&  <img src={headerLogo} alt="Logo" />
            }
          </Link>
        </div>

        <div className={styles['hamburger']} onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>

        <ul className={`${styles['nav-menu']} ${menuToggled ? styles['nav-menu-opened'] : ''}`}>
          {
            pageData?.Page?.data
              ?.filter((page: any) => page.isInMainMenu)
              ?.map((page: any) => (
                <li 
                  key={page.id}
                  onClick={() => setMenuToggled(false)}
                >
                  <Link to={page?.isHomePage ? '/' : `/${page?.slug}`}>{page?.title}</Link>
                </li>
              ))
          }

          {
            blogMenuPlacement === config.blogMenuPlacement.header &&
              <li 
                onClick={() => setMenuToggled(false)}
              >
                <Link to="/blog">Blog</Link>
              </li>
          }
        </ul>
      </div>
    </header>
  )
};

export default Header;
