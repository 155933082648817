import React, { useState } from 'react';
import { Switch, Route } from "react-router-dom";
import { gql, useQuery } from '@apollo/client';
import Spinner from 'react-bootstrap/Spinner';
import { Helmet } from "react-helmet";
import parse from 'html-react-parser';

import Header from './components/Header';
import Footer from './components/Footer';

import routes from './routes';

const SETTING = gql`
  query Setting {
    Setting {
      siteName
      primaryColor
      headerFont
      headerFontColor
      bodyFont
      bodyFontColor
      contactEmail
      blogMenuPlacement
      favicon {
        url
      }
      contact {
        name {
          display
          text 
        }
        email {
          display
          text 
        }
        phone {
          display
          text 
        }
        response {
          display
          text 
        }
      }
      header {
        headTagHtml
        companyLogo {
          url
        }
      }
      footer {
        backgroundColor
        facebookURL
        linkedInURL
        instagramURL
        sameAsHeader
        companyLogo {
          url
        }
      }

    }
  }
`;

export const Layout = () => {
  const [loading, setLoading] = useState(true);

  const { data: settingData } = useQuery(SETTING, {
    fetchPolicy: 'cache-first',
    onCompleted(data) {
      const setting = data?.Setting?.[0];
      if(setting) {
        const root = document.documentElement;
        root?.style?.setProperty( "--primary-color", setting?.primaryColor);
        root?.style?.setProperty( "--header-font", `${setting?.headerFont}, serif`);
        root?.style?.setProperty( "--body-font", `${setting?.bodyFont}, serif`);
        root?.style?.setProperty( "--header-color", setting?.headerFontColor);
        root?.style?.setProperty( "--body-color", setting?.bodyFontColor);

        if(setting?.favicon?.url) {
          const faviconEle = document.getElementById('favicon') as HTMLLinkElement;
          if(faviconEle) {
            faviconEle.href = setting?.favicon?.url;
          }
        }

      } else {
        document.title = 'Website';
      }
      setLoading(false);
    },
    onError() {
      setLoading(false);
    }
  });

  if(loading) {
    return (
      <div className="app-loading">
        <Spinner animation="grow" style={{ color: `var(--primary-color)` }}/>
      </div>
    )
  }

  const setting = settingData?.Setting?.[0];

  return (
    <>
      <Helmet>
        <title>{setting?.siteName ?? 'Website'}</title>
        {
          setting?.header?.headTagHtml&& parse(setting?.header?.headTagHtml)
        }
      </Helmet>

      <Header />
      <React.Suspense fallback={<p>Loading...</p>}>
        <Switch>
          <Route exact path={routes.home.path} component={routes.home.component} />
          <Route exact path={routes.blogs.path} component={routes.blogs.component} />
          <Route path={routes.blogBySlug.path(':slug')} component={routes.blogBySlug.component} />
          <Route path={routes.page.path(':pageSlug')} component={routes.page.component} />
        </Switch>
      </React.Suspense>
      <Footer />
    </>
  )
}

export default Layout;
