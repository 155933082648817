import { ReactNode } from 'react';

import styles from './style.module.scss';

interface Props {
  children: ReactNode;
}

const SectionContainer = (props: Props) => {
  return (
    <div className={styles['section-container']}>
      {props.children}
    </div>
  )
};

export default SectionContainer;

