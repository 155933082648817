import { CSSProperties } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { faFacebookF, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SectionContainer from '../SectionContainer';

import config from '../../config';

import styles from './style.module.scss';

const PAGE_LIST = gql`
  query ($input: PagePagingInput) {
    Page(input: $input) {
      data {
        id
        title
        isHomePage
        slug
        isInMainMenu
        isInFooter
      }
    }
  }
`

const SETTING = gql`
  query Setting {
    Setting {
      blogMenuPlacement
      contactEmail
      header {
        companyLogo {
          url
        }
      }
      footer {
        backgroundColor
        facebookURL
        linkedInURL
        instagramURL
        sameAsHeader
        companyLogo {
          url
        }
      }
    }
  }
`;

const Footer = () => {
  const { data: settingData } = useQuery(SETTING, {
    fetchPolicy: 'cache-only',
  });

  const setting = settingData?.Setting?.[0];

  const { data: pageData } = useQuery(PAGE_LIST, {
    fetchPolicy: 'cache-first',
    variables: {
      input: {
        paging: {
          orderBy: 'createdAt.asc',
        },
        args: {
          display: true,
        }
      },
    },
  });

  const headerLogo = setting?.header?.companyLogo?.url;
  const footerLogo = setting?.footer?.companyLogo?.url;
  const sameAsHeader = setting?.footer?.sameAsHeader;
  const blogMenuPlacement = setting?.blogMenuPlacement;
  const footerBackgroundColor = setting?.footer?.backgroundColor;

  const footerStyle: CSSProperties = {};
  if(footerBackgroundColor) {
    footerStyle.backgroundColor = footerBackgroundColor;
  };

  return (
    <footer>
      <div className={styles['footer-bottom-container']} style={footerStyle}>
        <SectionContainer>
          <div className={styles['footer-content']}>
            <div className={styles['logo-container']}>
              <div className={styles['logo']}>
                <img 
                  src={sameAsHeader ? headerLogo : footerLogo} 
                  alt="Logo" 
                />
              </div>

              <p>
                <span>
                  ©{(new Date()).getFullYear()} All Rights Reserved
                </span>
              </p>
            </div>

            <div className={styles['social-media']}>
              <a rel="noreferrer" target="_blank" href={setting?.footer?.facebookURL}>
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a rel="noreferrer" target="_blank" href={setting?.footer?.linkedInURL}>
                <FontAwesomeIcon icon={faLinkedinIn} />
              </a>
            </div>

            <ul className={styles['nav-menu']}>
              {
              pageData?.Page?.data
                ?.filter((page: any) => page.isInFooter)
                ?.map((page: any) => (
                  <li key={page.id}>
                    <Link to={page?.isHomePage ? '/' : `/${page?.slug}`}>{page.title}</Link>
                  </li>
              ))
              }

              {
                blogMenuPlacement === config.blogMenuPlacement.footer &&
                  <li>
                    <Link to="/blog">Blog</Link>
                  </li>
              }
            </ul>
          </div>
        </SectionContainer>
      </div>
    </footer>
  )
};

export default Footer;
